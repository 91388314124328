import 'twin.macro';
import React, { useRef, useState } from 'react';
import { BiPlus, BiMinus } from 'react-icons/bi';

const Collapse = ({ title, children }) => {
  const [active, setActive] = useState(false);
  const [height, setHeight] = useState('0px');

  const contentSpace = useRef(null);
  const toggleAccordion = () => {
    setActive(!active);
    setHeight(active ? '0px' : `${contentSpace.current?.scrollHeight}px`);
  };

  return (
    <div
      tw="flex flex-col outline-none border-t  border-black  last:border-b"
      role="button"
      tabIndex={0}>
      <div
        tw="flex flex-row items-center justify-between pt-5"
        onClick={toggleAccordion}>
        <h1 tw="cursor-pointer text-base font-bold uppercase">{title}</h1>
        <div>{active ? <BiMinus tw="h-5 w-5" /> : <BiPlus tw="h-5 w-5" />}</div>
      </div>
      <div
        ref={contentSpace}
        style={{ maxHeight: `${height}` }}
        tw="overflow-auto overflow-y-hidden duration-700 ease-in-out my-3">
        {children}
      </div>
    </div>
  );
};
export default Collapse;
