import React from 'react';
import 'twin.macro';
import { Heading, Subtitle, Container } from '../atoms';

const EmptyState = ({ title, message }) => (
  <Container>
    <div tw="flex flex-col w-4/6 m-auto items-center p-10 text-center bg-gray-200 mb-5">
      {title && <Heading>{title}</Heading>}
      {message && <Subtitle>{message}</Subtitle>}
    </div>
  </Container>
);

export default EmptyState;
