import 'twin.macro';
import React from 'react';

import { Heading } from '../atoms';
import { PortableText } from '../elements';
import SanityImage from '../global/SanityImage';

const ProductSidebar = ({ data }) => {
  if (!data) return null;
  return (
    <div>
      <div tw="flex flex-row justify-between items-center">
        <Heading variant="base" tw="mb-4 border-b my-6 mr-5 border-black w-4/6	">
          {data.title}
        </Heading>
        <div tw="object-contain w-4/12">
          {data.brand && data.brand.image && data.brand.image.asset && (
            <SanityImage
              fluid={data.brand.image.asset.gatsbyImageData}
              alt={data.brand.title || data.title}
            />
          )}
        </div>
      </div>
      <div tw="px-6 py-4 mt-4 bg-white">
        <PortableText>{data.body}</PortableText>
      </div>
    </div>
  );
};

export default ProductSidebar;
