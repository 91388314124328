import React from 'react';
import 'twin.macro';
import { Link } from 'gatsby';
import SanityImage from '../global/SanityImage';
import { Heading, Subtitle, Button } from '../atoms';

const Information = ({ heading, description, buttonText, to, brandImage }) => (
  <div tw="flex flex-col justify-center">
    <div tw="px-6 py-3">
      <div tw="md:flex md:justify-between my-4">
        <div>
          <Heading tw="font-bold text-xl mb-2">{heading}</Heading>
          <div tw="border mb-4 border-black" />
        </div>
        <div>
          {brandImage && <SanityImage tw="w-[200px]" fluid={brandImage} />}
        </div>
      </div>
      <Subtitle>{description}</Subtitle>
      {to && (
        <Link to={to}>
          <Button width="fullWidth">{buttonText || 'LEARN MORE'}</Button>
        </Link>
      )}
    </div>
  </div>
);

export default Information;
